import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import { userSlice } from "../pages/User/UserSlice";

import { diagnosticInformationSlice } from "../pages/DataInsightsHospital/Components/Slices/DiagnosticSlices";

const reducers = combineReducers({
  user: userSlice.reducer,

  diagnosticInformationDetails: diagnosticInformationSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});
