import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const map = new Map();
const abortController = (key) => {
  map.get(key)?.abort()
  const controller = new AbortController()
  map.set(key, controller)
  return controller.signal
}
const ABORTED = 'AbortError';
const errorCustomizer = (thunkAPI, e) => thunkAPI.rejectWithValue(e.name === ABORTED ? ABORTED : e.response.data)


export const getAverageStay = createAsyncThunk(
  "users/getAverageStay",
  async ({ token, locationId, from_date, to_date }, thunkAPI) => {
    const signal = abortController('getAverageStay');
    try {
      const response = await fetch(
        `https://app.pred.health/api/data-insights/hospital-location/admission/get-avg-length-of-stay/${locationId}/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getBifurcationData = createAsyncThunk(
  "users/getBifurcationData",
  async ({ token, locationId, from_date, to_date }, thunkAPI) => {
    const signal = abortController('getBifurcationData');
    try {
      const response = await fetch(
        `https://app.pred.health/api/data-insights/diagnostic-location/revenue/get-test-type-bifurcation-data/${locationId}/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getAppointmentDetails = createAsyncThunk(
  "users/getAppointmentDetails",
  async ({ token, locationId, from_date, to_date, category }, thunkAPI) => {
    const signal = abortController('getAppointmentDetails');
    try {
      const response = await fetch(
        `https://app.pred.health/api/data-insights/diagnostic-location/patient-footfall/get-appointment-data/${locationId}/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getAppointmentsPerDay = createAsyncThunk(
  "users/getAppointmentsPerDay",
  async ({ token, locationId, from_date, to_date, category }, thunkAPI) => {
    const signal = abortController('getAppointmentsPerDay');
    try {
      const response = await fetch(
        `https://app.pred.health/api/data-insights/diagnostic-location/patient-footfall/get-number-of-tests-appointments-per-day/${locationId}/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getRevenuePerTest = createAsyncThunk(
  "users/getRevenuePerTest",
  async ({ token, locationId, from_date, to_date, category }, thunkAPI) => {
    const signal = abortController('getRevenuePerTest');
    try {
      const response = await fetch(
        `https://app.pred.health/api/data-insights/diagnostic-location/revenue/get-revenue-per-test/${locationId}/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getTotalRevenue = createAsyncThunk(
  "users/getTotalRevenue",
  async ({ token, locationId, from_date, to_date, category }, thunkAPI) => {
    const signal = abortController('getTotalRevenue');
    try {
      const response = await fetch(
        `https://app.pred.health/api/data-insights/diagnostic-location/revenue/get-total-revenue/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getBedOccupancyData = createAsyncThunk(
  "users/getBedOccupancyData",
  async ({ token, locationId }, thunkAPI) => {
    const signal = abortController('getBedOccupancyData');
    try {
      const response = await fetch(
        `https://app.pred.health/api/data-insights/hospital-location/admission/get-current-bed-occupancy/${locationId}/`,
        {
          method: "GET",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          signal
        }
      );

      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getSurgeryList = createAsyncThunk(
  "users/getSurgeryList",
  async ({ token, locationId, from_date, to_date, query }, thunkAPI) => {
    const signal = abortController('getSurgeryList');
    try {
      const response = await fetch(
        `https://app.pred.health/api/data-insights/hospital-location/surgery/get-surgery-search-results/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
            q: query,
          }),
          signal
        }
      );

      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getSurgeryData = createAsyncThunk(
  "users/getSurgeryData",
  async (
    { token, locationId, from_date, to_date, surgeryList, filterText },
    thunkAPI
  ) => {
    const signal = abortController('getSurgeryData');
    try {
      const response = await fetch(
        `https://app.pred.health/api/data-insights/hospital-location/surgery/get-surgery-data/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
            surgery_list: surgeryList ? [surgeryList] : [],
            custom_filter_text: filterText,
          }),
          signal
        }
      );

      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const getAppointmentReferralData = createAsyncThunk(
  "users/getAppointmentReferralData",
  async (
    { token, locationId, from_date, to_date, surgeryList, filterText },
    thunkAPI
  ) => {
    const signal = abortController('getAppointmentReferralData');
    try {
      const response = await fetch(
        `https://app.pred.health/api/data-insights/diagnostic-location/referral/get-referral-data/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );

      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const diagnosticInformationSlice = createSlice({
  name: "diagnosticInformationDetails",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    reportsDetails: [],
    averageStay: "",
    bifurcationData: [],
    appointmentDetails: [],
    surgeryData: [],
    surgeryDataList: [],
    bedOccupancy: 0,
    appointmentPerDay: [],
    revenue: [],
    revenueLoading: false,
    referralData: [],
    referralDataLoading: false,
    totalRevenue: 0,
    totalRevenueLoading: false,

    appointmentDetailsLoading: false,
    bifurcationDataLoading: false,
    appointmentPerDayLoading: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [getBifurcationData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.bifurcationDataLoading = false;
      state.isSuccess = true;
      state.bifurcationData = payload;
      return state;
    },
    [getBifurcationData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.bifurcationDataLoading = payload === ABORTED ? true : false;

      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getBifurcationData.pending]: (state) => {
      state.isFetching = true;
      state.bifurcationDataLoading = true;
    },
    [getAppointmentReferralData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.referralDataLoading = false;
      state.isSuccess = true;
      state.referralData = payload;
      return state;
    },
    [getAppointmentReferralData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.referralDataLoading = payload === ABORTED ? true : false;

      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getAppointmentReferralData.pending]: (state) => {
      state.isFetching = true;
      state.referralDataLoading = true;
    },
    [getTotalRevenue.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.totalRevenueLoading = false;
      state.isSuccess = true;
      state.totalRevenue = payload;
      return state;
    },
    [getTotalRevenue.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.totalRevenueLoading = payload === ABORTED ? true : false;

      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getTotalRevenue.pending]: (state) => {
      state.isFetching = true;
      state.totalRevenueLoading = true;
    },
    [getRevenuePerTest.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.revenueLoading = false;
      state.isSuccess = true;
      state.revenue = payload;
      return state;
    },
    [getRevenuePerTest.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.revenueLoading = payload === ABORTED ? true : false;

      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getRevenuePerTest.pending]: (state) => {
      state.isFetching = true;
      state.revenueLoading = true;
    },
    [getAppointmentsPerDay.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.appointmentDetailsLoading = false;
      state.isSuccess = true;
      state.appointmentPerDay = payload;
      return state;
    },
    [getAppointmentsPerDay.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.appointmentDetailsLoading = payload === ABORTED ? true : false;

      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getAppointmentsPerDay.pending]: (state) => {
      state.isFetching = true;
      state.appointmentDetailsLoading = true;
    },
    [getAverageStay.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.averageStay = payload.avg_length_of_stay;
      return state;
    },
    [getAverageStay.rejected]: (state, { payload }) => {
      state.isFetching = payload === ABORTED ? true : false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getAverageStay.pending]: (state) => {
      state.isFetching = true;
    },
    [getSurgeryList.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.surgeryDataList = payload;
      return state;
    },
    [getSurgeryList.rejected]: (state, { payload }) => {
      state.isFetching = payload === ABORTED ? true : false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getSurgeryList.pending]: (state) => {
      state.isFetching = true;
    },
    [getSurgeryData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.surgeryData = payload;
      return state;
    },
    [getSurgeryData.rejected]: (state, { payload }) => {
      state.isFetching = payload === ABORTED ? true : false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getSurgeryData.pending]: (state) => {
      state.isFetching = true;
    },

    [getAppointmentDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.appointmentDetails = payload;
      state.appointmentDetailsLoading = false;
      return state;
    },
    [getAppointmentDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
      state.appointmentDetailsLoading = payload === ABORTED ? true : false;
    },
    [getAppointmentDetails.pending]: (state) => {
      state.isFetching = true;
      state.appointmentDetailsLoading = true;
    },
    [getBedOccupancyData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.bedOccupancy = payload?.number_of_bed_occupancy;
      return state;
    },
    [getBedOccupancyData.rejected]: (state, { payload }) => {
      state.isFetching = payload === ABORTED ? true : false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getBedOccupancyData.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = diagnosticInformationSlice.actions;

export const diagnosticInformationSelector = (state) =>
  state.diagnosticInformationDetails;
