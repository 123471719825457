import React, { useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Radio, Empty, Spin } from "antd";

const PieChart = ({ genderDetails, appointmentDetailsLoading }) => {
  const [patientData, setPatientData] = useState([]);
  useEffect(() => {
    let data = genderDetails?.all_gender_chart_data?.map((items) => {
      return {
        key: items?.patient_count,
        id: `Age Group ${items?.age_group}`,
        age_group: items?.age_group,
        value: items?.patient_count,
        label: `Age  ${items?.age_group}`,
      };
    });
    setPatientData(data);
  }, [genderDetails]);

  function handleChange(value) {
    let data = genderDetails[value.target.value]?.map((items) => {
      return {
        key: items?.age_group,
        id: `Age Group ${items?.age_group}`,
        age_group: items?.age_group,
        value: items?.patient_count,
        label: `Age  ${items?.age_group}`,
      };
    });
    setPatientData(data);
  }

  return (
    <>
      {patientData && patientData?.length ? (
        <div className="pie-graph-container">
          <h1 className="graph-heading-container">age gender patient data</h1>

          {appointmentDetailsLoading ? (
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 70,
              }}
            ></Spin>
          ) : (
            <>
              {" "}
              <div className="radio-button-gender">
                <Radio.Group
                  defaultValue="all_gender_chart_data"
                  buttonStyle="solid"
                  onChange={handleChange}
                  className="gender-selector"
                >
                  <Radio.Button value="all_gender_chart_data">All</Radio.Button>
                  <Radio.Button value="male_gender_chart_data">
                    Male
                  </Radio.Button>
                  <Radio.Button value="female_gender_chart_data">
                    Female
                  </Radio.Button>
                  <Radio.Button value="unknown_gender_chart_data">
                    Unknown
                  </Radio.Button>
                </Radio.Group>
              </div>
              <ResponsivePie
                data={patientData}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: "color",
                  modifiers: [["darker", 2]],
                }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: "ruby",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "c",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "go",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "python",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "scala",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "lisp",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "elixir",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "javascript",
                    },
                    id: "lines",
                  },
                ]}
                legends={[
                  {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    itemDirection: "left-to-right",
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
              />
            </>
          )}
        </div>
      ) : (
        <div className="pie-graph-container">
          <h1 className="graph-heading-container">age gender patient data</h1>
          <div className="radio-button-gender">
            <Radio.Group
              defaultValue="all_gender_chart_data"
              buttonStyle="solid"
              onChange={handleChange}
              className="gender-selector"
            >
              <Radio.Button value="all_gender_chart_data">All</Radio.Button>
              <Radio.Button value="male_gender_chart_data">Male</Radio.Button>
              <Radio.Button value="female_gender_chart_data">
                Female
              </Radio.Button>
              <Radio.Button value="unknown_gender_chart_data">
                Unknown
              </Radio.Button>
            </Radio.Group>
          </div>

          <Empty />
        </div>
      )}
    </>
  );
};

export default PieChart;
