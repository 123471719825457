import styled from "styled-components";

const ParentWrapper = styled.div`
  .ant-modal-footer {
    display: none;
  }
  .sessionTimeoutModal .ant-modal-header {
    display: none;
  }

  .ant-modal-header {
    display: none;
  }

  button.ant-btn.ant-btn-primary.logout-btn {
    margin-left: 13px;
  }

  .button-container button {
    font-weight: 500;
    font-size: 8px;
    box-shadow: rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px;
  }

  .ant-card {
    box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px,
      rgb(14 30 37 / 32%) 0px 2px 16px 0px;
  }

  .ant-spin.ant-spin-spinning {
    display: flex;
    margin-top: 10px;
    margin-left: 5px;
  }

  .support-container {
    display: flex;
  }

  .warning-txt {
    display: block;
    font-size: 12px;
    font-style: italic;
    color: #e21c21;
    font-weight: 600;
  }

  .filter-button {
    font-size: 8px !important;
    height: 28px;
    width: 86px;
    margin: 6px;
    background: #777;
  }

  .active {
    background: #045185;
  }

  .radio-button-gender .graph-heading-container {
    position: relative;
    text-align: left;
    left: 15px;
  }

  .ant-picker.ant-picker-range.custom-range-picker {
    width: 351px;
  }

  .filter-date-button {
    background: #045185;
    border-color: #045185;
  }

  .range-selector {
    margin-top: 8px;
    margin-bottom: 5px;
  }
  .main.ant-layout-content.site-layout-background {
    background-color: red;
  }

  .col-container {
    margin-top: 17px;
    width: 100%;
    display: flex;
  }

  .ant-card {
    margin-bottom: 21px;
    border-radius: 15px;
  }

  .ant-card-head {
    border-bottom: none;
  }

  .ant-card-head-title {
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    /* color: #045185; */
  }

  .icon {
    font-size: 60px;
    color: #045185;
    bottom: 0px;
    position: relative;
    top: 5px;
  }

  .rupee {
    top: 13px;
  }

  .ant-card-body {
    padding: 0px 20px;
    // border: 1px solid #045185;
    border-radius: 15px;
  }

  .card-value {
    font-size: 35px;
    text-align: left;
    width: 100%;
    display: block;
    font-weight: 700;
  }

  .content {
    display: flex;
  }

  .content-values {
    margin-left: 13px;
  }

  h2.card-title {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 0;
    /* margin-left: 1px; */
  }

  .number {
    font-size: 20px;
    color: #045185;
  }

  .top-header-filter {
    display: flex;
    margin-top: 20px;
  }

  .button-container-card {
    position: absolute;
    right: 10px;
    top: 8px;
  }

  .ant-list.ant-list-split {
    padding: 20px;
    width: 50%;
    height: 432px;
    margin-right: 17px;
    background: #fff;
    box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px,
      rgb(14 30 37 / 32%) 0px 2px 16px 0px;
  }

  .ant-spin-nested-loading {
    height: 335px;
    overflow-y: scroll;
    padding: 0 14px;
  }

  /* width */
  .ant-spin-nested-loading::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .ant-spin-nested-loading::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  /* Handle */
  .ant-spin-nested-loading::-webkit-scrollbar-thumb {
    background: #045185;
    border-radius: 10px;
  }

  /* Handle on hover */
  .ant-spin-nested-loading::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  .ant-list-item-meta-content {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
  }

  .left {
    left: 25px;
  }

  .bottom-header-filter {
    display: flex;
    margin-top: 10px;
    height: 55vh;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  }

  .ant-list-header {
    font-size: 17px;
    font-weight: 600;
    text-align: initial;
    text-transform: capitalize;
  }

  .button-container-graph {
    position: absolute;
    right: 15px;
    z-index: 2;
  }

  button.ant-btn.ant-btn-primary {
    background: #045185;
    border: #045185;
  }

  .ant-list-item-meta-description {
    font-size: 20px;
    font-weight: 600;
    color: #045185;
  }

  .pie-graph-container,
  .bar-graph-container {
    position: relative;
    width: 100%;
    height: 58vh;
    padding: 35px;
    background-color: #fff;
    box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px,
      rgb(14 30 37 / 32%) 0px 2px 16px 0px;
  }

  .pie-graph-container {
    width: 50% !important;
  }

  .parent-table {
    width: 50% !important;
    background-color: #fff;
    overflow: hidden;
    margin-right: 14px;
    box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px,
      rgb(14 30 37 / 32%) 0px 2px 16px 0px;
  }

  .parent-table .ant-spin-nested-loading {
    overflow: hidden;
  }

  .radio-button-gender {
    margin-top: 12px;
  }

  li.recharts-legend-item.legend-item-0 {
    display: none !important;
  }

  .no-padding {
    padding-left: 0;
  }

  h1.graph-heading-container {
    font-size: 14px;
    font-style: italic;
    text-transform: capitalize;
    margin: 12px 0;
    font-weight: 600;
    position: relative;
    top: 0px;
    position: absolute;
    color: #045185;
  }

  h1.graph-heading-container.total-container {
    right: 25px !important;
  }

  .graph-container {
    width: 69%;
    background-color: #fff;
    height: 42vh;
    margin-top: 18px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  }

  .bar-graph-container-revenue {
    position: relative;
    width: 100%;
    height: 35vh;
    padding: 2px;
  }

  .top-header-filter-revenue {
    justify-content: space-between;
    display: flex;
  }

  .bar-graph-container-revenue {
    margin-bottom: 140px;
  }

  .add-container {
    display: flex;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  span.dashboard-title {
    display: block;
    display: flex;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 700;
    color: #e21c21;
    font-style: italic;
  }

  .margin-top-surgery {
    margin-top: 10px !important;
  }

  .none {
    display: none;
  }

  .surgery-btn {
    margin: 10px;
  }

  .ant-select-arrow {
    display: none;
  }

  button.ant-btn.ant-btn-primary.ant-btn-icon-only {
    background: #045185;
    border-color: #045185;
  }

  .col-container-surgery {
    width: fit-content;
  }

  .dynamic-charts {
    width: 100%;
    padding: 6px;
    height: 50vh;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    margin-top: 25px;
  }

  .select-container {
    display: flex;
    padding: 6px;
    z-index: 10;
  }

  .ant-card {
    margin-left: 15px;
  }

  .decimal-value {
    font-size: 19px;
    font-weight: 500;
    color: grey;
  }

  .ant-select.ant-select-single.ant-select-show-arrow {
    z-index: 2;
  }

  span.ant-radio-button.ant-radio-button-checked {
    background: #045185;
  }

  label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border-color: #045185;
  }

  .header-container {
    display: flex;
    justify-content: center;
  }

  button.ant-btn.ant-btn-primary.add-btn {
    position: absolute;
    right: 5px;
    background-color: #045185;
    border-color: #045185;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    top: 5px;
  }

  label.ant-form-item-required {
    font-weight: 600;
  }

  .margin-top {
    position: absolute !important;
    top: 20px !important;
    left: 17px;
  }

  .ant-empty {
    margin-top: 100px;
  }

  /* width */
  .col-container::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }

  /* Track */
  .col-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  /* Handle */
  .col-container::-webkit-scrollbar-thumb {
    background: #045185;
    border-radius: 10px;
  }

  /* Handle on hover */
  .col-container::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  span.sub-details {
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 500;
    margin-left: 8px;
    font-style: italic;
  }

  button.ant-btn.ant-btn-primary {
    background-color: #045185;
    border: #045185;
  }

  @media screen and (max-width: 768px) {
    .parent-table {
      width: 100% !important;
    }

    .col-container {
      margin-top: 17px;
      width: 100%;
      display: block;
    }

    .ant-card {
      width: 100%;
      margin-left: 0;
    }

    .top-header-filter,
    .header-container {
      display: block;
    }

    .pie-graph-container {
      margin-top: 20px;
      width: 100% !important;
    }
    .ant-list.ant-list-split {
      width: 100% !important;
    }

    .ant-picker.ant-picker-range.custom-range-picker {
      width: 257px;
    }

    button.ant-btn.ant-btn-primary {
      background: #045185;
      border: #045185;
    }

    button.ant-btn.ant-btn-primary.add-btn {
      position: relative;
      margin-bottom: 20px;
    }
  }
`;

export default ParentWrapper;
