/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Table, Radio, Empty, Spin } from "antd";

const DoctorAdmission = ({
  test_data_type_bifurcation_data,
  bifurcationDataLoading,
}) => {
  const [selectedDataFlow, setSelectedDataFlow] = useState(
    test_data_type_bifurcation_data && test_data_type_bifurcation_data[0]?.data
  );
  const [headers, setHeaders] = useState();

  useEffect(() => {
    setSelectedDataFlow(
      test_data_type_bifurcation_data &&
        test_data_type_bifurcation_data[0]?.data
    );

    let headers =
      test_data_type_bifurcation_data &&
      test_data_type_bifurcation_data.map((items) => {
        return items.test_type;
      });
    setHeaders(headers);
  }, [test_data_type_bifurcation_data]);

  const handleChange = (value) => {
    let obj = test_data_type_bifurcation_data.find(
      (o) => o.test_type === value.target.value
    );

    setSelectedDataFlow(obj.data);
  };

  const columns = [
    {
      title: "Modality",
      dataIndex: "modality",
      key: "modality",
    },
    {
      title: "No of Appointments",
      dataIndex: "number_of_appointments",
      key: "number_of_appointments",
      sorter: {
        compare: (a, b) => a.number_of_appointments - b.number_of_appointments,
        multiple: 3,
      },
    },

    {
      title: "No of Invoiced",
      dataIndex: "number_of_invoiced_tests",
      key: "number_of_invoiced_tests",
      sorter: {
        compare: (a, b) =>
          a.number_of_invoiced_tests - b.number_of_invoiced_tests,
        multiple: 2,
      },
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      sorter: {
        compare: (a, b) => a.revenue - b.revenue,
        multiple: 1,
      },
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <>
      <div className="parent-table">
        {bifurcationDataLoading ? (
          <Spin
            style={{ display: "flex", justifyContent: "center", marginTop: 70 }}
          />
        ) : (
          <div className="radio-button-gender">
            <h1 className="graph-heading-container">bifurcation data type</h1>
            {test_data_type_bifurcation_data &&
            test_data_type_bifurcation_data.length ? (
              <>
                <Radio.Group
                  defaultValue={"Pathology"}
                  buttonStyle="solid"
                  onChange={handleChange}
                  className="gender-selector"
                >
                  {headers &&
                    headers?.map((item) => {
                      return (
                        <Radio.Button value={item} key={item}>
                          {item}
                        </Radio.Button>
                      );
                    })}
                </Radio.Group>
                <Table
                  dataSource={selectedDataFlow}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: 240 }}
                  onChange={onChange}
                />
              </>
            ) : (
              <>
                <Empty />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DoctorAdmission;
