/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Layout, Modal, Button, Form, Input } from "antd";

import NavigationBar from "./Components/NavigationBar";
import RightSideBar from "./Components/RightSideBar";
import LeftSideBar from "./Components/LeftSideBar";
import MainContent from "./Components/MainContent";
import useWindowDimensions from "../../components/common/useWindowDimensions ";
import ParentWrapper from "./style";

import {
  userSelector,
  fetchUserBytoken,
  clearState,
  loginUser,
  loginUserByToken,
  doctorActivityTracking
} from "../User/UserSlice";
import queryString from "query-string";

import IdleTimer from "react-idle-timer";
import Logo from "../../components/common/logo";

const DataInsightsDashboardHospital = (props) => {
  const { Header, Content } = Layout;
  const { width } = useWindowDimensions();
  const idealTimerRef = useRef(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const { isError, doctorDetails } = useSelector(userSelector);
  const [activeTabKey, setActiveTabKey] = useState(1);
  const [darkMode, setDarkMode] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [sessionTimeOUt, setSessionTImeOut] = useState(false);
  const [activePatientData, setPatientData] = useState([]);

  const [activePatientDetails, setActivePatientDetails] = useState([]);
  const [loggedUserDetails, setDoctorDetails] = useState([]);
  const { userDetails } = useSelector(userSelector);

  useEffect(() => {
    const value = queryString.parse(props.location.search);
    const token = value.token;
    const key = value.key;
    if (props?.location?.state?.key) {
      dispatch(
        loginUserByToken({
          key: props?.location?.state?.key,
          token: props?.location?.state?.token,
        }),
        fetchUserBytoken({ token: localStorage.getItem("token") })
      );
    }

    if (!localStorage.getItem("token")) {
      dispatch(clearState());
      history.push("/login");
    }

    setDoctorDetails(JSON.parse(localStorage.getItem("doctorProfile")));

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  useEffect(() => {
    dispatch(
      doctorActivityTracking({
        token: localStorage.getItem("token"),
        doctorProfileId: null,
        locationId: JSON.parse(localStorage.getItem("doctorProfile"))?.selected_location,
        mainFeature: 'Page Visited',
        subFeature: 'Data Insights',
        context: null,
        uniqueId: null,
        specialty: null
      })
    );
  }, []);

  useEffect(() => {
    setDoctorDetails(JSON.parse(localStorage.getItem("doctorProfile")));
  }, [localStorage.getItem("doctorProfile")]);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      history.push("/login");
    }
  }, [isError]);

  const onIdle = () => {
    setSessionTImeOut(true);
  };

  const changeTabKey = (key) => {
    setActiveTabKey(key);
    setCollapsed(true);
  };

  const onFinish = async (values) => {
    let cards = {
      username: doctorDetails.username,
      password: values.password,
    };
    let data = await dispatch(loginUser(cards));

    if (data.error) {
      dispatch(clearState());
      history.push("/login");
    } else {
      setSessionTImeOut(false);
    }
  };

  const toggleDarkMode = (value) => {
    setDarkMode(value);
  };

  const setLeftSideBar = () => {
    setCollapsed(!collapsed);
  };

  const onLogOut = () => {
    localStorage.clear();
    history.push("/login");
  };

  const patientData = (id, name) => {
    let patientInformation = {
      patientId: id,
      patientName: name,
      doctorId: loggedUserDetails?.list_of_profiles?.doctors[0]?.id
        ? loggedUserDetails?.list_of_profiles?.doctors[0].id
        : loggedUserDetails?.selected_doctor_profile,
    };
    setPatientData(patientInformation);
    setActivePatientDetails([...activePatientDetails, patientInformation]);
  };

  return (
    <ParentWrapper>
      <Layout>
        <IdleTimer
          ref={idealTimerRef}
          timeout={10800000 * 10000}
          onIdle={onIdle}
        ></IdleTimer>
        <Modal
          visible={sessionTimeOUt}
          footer={<div />}
          closable={false}
          className="sessionTimeoutModal"
        >
          <div style={{ textAlign: "center" }}>
            <Logo />
          </div>
          <h2
            style={{
              fontSize: 15,
              textAlign: "center",
              marginBottom: 13,
              fontWeight: 700,
            }}
          >
            You've been idle for a while please login to continue the session
          </h2>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 10 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  background: "#045185",
                  borderColor: "#045185",
                  height: 38,
                  borderRadius: 5,
                }}
              >
                Login
              </Button>
              <Button
                type="primary"
                className="logout-btn"
                onClick={() => onLogOut()}
                style={{
                  marginLeft: 20,
                  background: "#045185",
                  borderColor: "#045185",
                  height: 38,
                  borderRadius: 5,
                }}
              >
                Logout
              </Button>
            </Form.Item>
          </Form>{" "}
        </Modal>
        <LeftSideBar
          collapsed={collapsed}
          darkMode={darkMode}
          loggedUserDetails={loggedUserDetails}
          patientData={patientData}
          LeftSideBar={setLeftSideBar}
          changeTabKey={changeTabKey}
        />
        <Layout className="site-layout">
          {width > 414 ? (
            <></>
          ) : (
            <Header
              className="site-layout-background"
              style={{ padding: 0, zIndex: 2 }}
            >
              <NavigationBar
                toggleDarkMode={toggleDarkMode}
                darkMode={darkMode}
                LeftSideBar={setLeftSideBar}
                collapsed={collapsed}
                userDetails={userDetails}
              />
            </Header>
          )}

          <Content
            className="site-layout-background"
            style={{
              padding: "7px  7px",
              minHeight: 280,
              backgroundColor: darkMode ? "#15192A" : "#e7e9eb",
              zIndex: 1,
            }}
          >
            <MainContent
              activePatientData={activePatientData}
              patientData={patientData}
              activeTabKey={activeTabKey}
            />
          </Content>
        </Layout>
        {width > 414 ? (
          <RightSideBar
            toggleDarkMode={toggleDarkMode}
            darkMode={darkMode}
            onLogOut={onLogOut}
          />
        ) : (
          <></>
        )}
      </Layout>
    </ParentWrapper>
  );
};

export default DataInsightsDashboardHospital;
