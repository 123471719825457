import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/Login";
import { PrivateRoute } from "./routes/PrivateRoute";
import DataInsightsDashboardHospital from "./pages/DataInsightsHospital";
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact component={Login} path="/login" />

          <Route
            exact
            component={DataInsightsDashboardHospital}
            path="/data-insights-diagnostic"
          />

          <PrivateRoute
            exact
            component={DataInsightsDashboardHospital}
            path="/authenticate/:token/:key"
          />
          <PrivateRoute
            exact
            component={DataInsightsDashboardHospital}
            path="/"
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
