import { Card, Spin } from "antd";
import { UsergroupAddOutlined, FileOutlined } from "@ant-design/icons";

const DataPatientCard = ({
  averageStay,
  totalAdmission,
  totalTest,
  appointmentDetailsLoading,
}) => {
  return (
    <div className="col-container">
      <Card bordered={false}>
        <div className="content">
          <div className="icon">
            <UsergroupAddOutlined />
          </div>
          <div className="content-values">
            <h2 className="card-title">Total Appointments</h2>
            {appointmentDetailsLoading ? (
              <Spin />
            ) : (
              <span className="card-value">
                {totalAdmission ? totalAdmission : 0}
              </span>
            )}
          </div>
        </div>
      </Card>

      <Card bordered={false} className="avg-len-container">
        <div className="content">
          <div className="icon">
            <FileOutlined />
          </div>
          <div className="content-values">
            <h2 className="card-title avg-len">Total Tests</h2>
            {appointmentDetailsLoading ? (
              <Spin />
            ) : (
              <span className="card-value">{totalTest ? totalTest : 0}</span>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DataPatientCard;
