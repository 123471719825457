/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { Layout, Menu } from "antd";
import {
  WalletOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import useWindowDimensions from "../../../../components/common/useWindowDimensions ";
import Logo from "../../../../components/common/logo";

import { LeftSideBarWrapper } from "./style";

const LeftSideBar = ({ collapsed, darkMode, LeftSideBar, changeTabKey }) => {
  const { width } = useWindowDimensions();

  const { Sider } = Layout;

  var imgUrl = `${process.env.PUBLIC_URL}/pred_health_wo_bg.png`

  return (
    <LeftSideBarWrapper
      style={{
        width: width > 414 ? "" : "100%",
        display: collapsed && width <= 414 ? "none" : "block",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        theme="light"
        className={darkMode ? "dark-mode left-panel" : "left-panel"}
        collapsedWidth="50"
        width={width > 414 ? "250" : "100%"}
        onCollapse={LeftSideBar}
      >
        {/* <Logo /> */}
        <div className="logo">
          {!collapsed ? (
            <div className="logo-container">
              <img
                src={imgUrl}
                height="40"
                alt="pred"
              />
            </div>
          ) : (
            <h1 className="short-logo">
              P<span className="h-text">H</span>
            </h1>
          )}
        </div>
        <Menu defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item
            key="1"
            icon={<UsergroupAddOutlined />}
            onClick={() => changeTabKey(1)}
          >
            Patient Footfall
          </Menu.Item>

          <Menu.Item
            key="4"
            icon={<UserSwitchOutlined />}
            onClick={() => changeTabKey(4)}
          >
            Referrals
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={<WalletOutlined />}
            onClick={() => changeTabKey(2)}
          >
            Revenue Analysis
          </Menu.Item>
        </Menu>
      </Sider>
    </LeftSideBarWrapper>
  );
};

export default LeftSideBar;
