/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { DatePicker, Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";

import {
  getBifurcationData,
  diagnosticInformationSelector,
  getAppointmentDetails,
  getAppointmentsPerDay,
} from "../../Slices/DiagnosticSlices";

import FilterButton from "./Components/FilterButtons";
import DataPatientCard from "./Components/DataCards";
import DoctorAdmission from "./Components/DoctorAdmission";
import Scrollbars from "react-custom-scrollbars";
import PieChart from "./Components/PieChart";
import BarGraph from "./Components/BarGraph";
import moment from "moment";

const Admission = () => {
  const { RangePicker } = DatePicker;
  const dateFormat = "DD/MM/YYYY";
  const [selectedDate, setSelectedDate] = useState([]);
  const [dateSelected, setDateSelected] = useState(7);
  const [activeButton, setActiveButton] = useState([]);

  const doctorProfile = JSON.parse(localStorage.getItem("doctorProfile"));

  const dispatch = useDispatch();

  function disableDateRanges(range = { startDate: false, endDate: false }) {
    const { startDate, endDate } = range;
    return function disabledDate(current) {
      let startCheck = true;
      let endCheck = true;
      if (startDate) {
        startCheck = current && current < moment(startDate, "YYYY-MM-DD");
      }
      if (endDate) {
        endCheck = current && current > moment(endDate, "YYYY-MM-DD");
      }
      return (startDate && startCheck) || (endDate && endCheck);
    };
  }

  const {
    averageStay,
    bifurcationData,
    appointmentDetails,
    appointmentDetailsLoading,
    appointmentPerDay,
    bifurcationDataLoading,
  } = useSelector(diagnosticInformationSelector);

  const getDate = (from_date, to_date, days) => {
    setDateSelected(days);

    if (days === 0) {
      dispatch(
        getAppointmentDetails({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );
      dispatch(
        getBifurcationData({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );
      dispatch(
        getAppointmentsPerDay({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );
    } else {
      dispatch(
        getAppointmentDetails({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );
      dispatch(
        getBifurcationData({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );
      dispatch(
        getAppointmentsPerDay({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getAppointmentDetails({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getBifurcationData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getAppointmentsPerDay({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      })
    );
  }, []);

  const filterResult = () => {
    setActiveButton(null);
    if (selectedDate.length) {
      dispatch(
        getAppointmentDetails({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0])?.format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1])?.format("YYYY-MM-DD"),
        })
      );
      dispatch(
        getBifurcationData({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0])?.format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1])?.format("YYYY-MM-DD"),
        })
      );
      dispatch(
        getAppointmentsPerDay({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );
    } else if (dateSelected === 0) {
      dispatch(
        getAppointmentDetails({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );
      dispatch(
        getBifurcationData({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );
      dispatch(
        getAppointmentsPerDay({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );
    } else {
      dispatch(
        getAppointmentDetails({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );
      dispatch(
        getBifurcationData({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );
      dispatch(
        getAppointmentsPerDay({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );
    }
  };

  const onChange = (dateString) => {
    setSelectedDate(dateString);
  };

  return (
    <>
      <Scrollbars style={{ height: "95vh" }}>
        <div className="range-selector">
          <RangePicker
            defaultValue={[moment(new Date()), moment(new Date())]}
            format={dateFormat}
            onChange={onChange}
            value={
              selectedDate?.length
                ? [selectedDate[0], selectedDate[1]]
                : dateSelected === 0
                ? [moment(new Date()), moment(new Date())]
                : [moment(new Date()), moment(new Date())]
            }
            className="custom-range-picker"
            disabledDate={disableDateRanges({
              startDate: new Date("2022-01-23"),
            })}
          />
          <Button
            type="primary"
            icon={<FilterOutlined />}
            onClick={() => filterResult()}
            className="filter-date-button"
          />
          <span className="warning-txt">
            *Please Select a range of date's to show the data insights
          </span>
        </div>
        <FilterButton setFilterDate={getDate} setSelectedDate={setSelectedDate} activeButton={activeButton} setActiveButton={setActiveButton} />
        <DataPatientCard
          averageStay={averageStay}
          totalAdmission={appointmentDetails?.total_numbers_of_admission}
          totalTest={appointmentDetails?.total_numbers_of_test}
          appointmentDetailsLoading={appointmentDetailsLoading}
        />
        <div className="top-header-filter">
          <DoctorAdmission
            test_data_type_bifurcation_data={
              bifurcationData?.test_data_type_bifurcation_data
            }
            bifurcationDataLoading={bifurcationDataLoading}
          />
          <PieChart
            genderDetails={appointmentDetails?.age_gender_patient_data}
            appointmentDetailsLoading={appointmentDetailsLoading}
          />
        </div>
        <div className="bottom-header-filter">
          <BarGraph
            number_of_tests_appointment_per_day={
              appointmentPerDay?.number_of_tests_appointment_per_day
            }
            appointmentDetailsLoading={appointmentDetailsLoading}
          />
        </div>
      </Scrollbars>
    </>
  );
};

export default Admission;
