import { List, Avatar, Spin } from "antd";

const DoctorAdmission = ({ revenue_per_test_list, revenueLoading }) => {
  let total = 0;

  revenue_per_test_list?.map((items) => (total = total + items.revenue));

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={revenue_per_test_list}
        className="revenue-list"
        header={
          <div>
            <h1 className="graph-heading-container">Revenue Per Test</h1>

            {/* <h1 className="graph-heading-container total-container">
              Total Amount :-{" "}
              {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              }).format(parseInt(total).toFixed(2))}
            </h1> */}
          </div>
        }
        renderItem={(item) =>
          revenueLoading ? (
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 70,
              }}
            />
          ) : (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar src="https://www.medstar911.org/wp-content/uploads/2019/11/Doctor-placeholder-image.jpg" />
                }
                title={item?.procedure.name}
                description={new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(parseInt(item?.revenue).toFixed(2))}
                className="list-data"
              />
            </List.Item>
          )
        }
      />
    </>
  );
};

export default DoctorAdmission;
