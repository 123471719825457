/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";

import { Radio, Empty, Spin } from "antd";

const PieChart = ({
  test_data_type_bifurcation_data,
  bifurcationDataLoading,
}) => {
  const [patientData, setPatientData] = useState([
    test_data_type_bifurcation_data &&
      test_data_type_bifurcation_data[0] &&
      test_data_type_bifurcation_data[0].data,
  ]);

  const [dataSerries, setData] = useState([]);

  useEffect(() => {
    let data =
      test_data_type_bifurcation_data &&
      test_data_type_bifurcation_data[0] &&
      test_data_type_bifurcation_data[0].data &&
      test_data_type_bifurcation_data &&
      test_data_type_bifurcation_data[0] &&
      test_data_type_bifurcation_data[0].data?.map((items) => {
        return {
          id: items?.modality,
          revenue: items?.revenue,
          value: items?.revenue,
          label: items?.modality,
        };
      });
    setData(data);
  }, [test_data_type_bifurcation_data]);

  let radioColumns =
    test_data_type_bifurcation_data &&
    test_data_type_bifurcation_data?.map((items) => {
      return items.test_type;
    });

  function handleChange(value) {
    let obj = test_data_type_bifurcation_data.find(
      (o) => o.test_type === value.target.value
    );
    let data =
      obj &&
      obj?.data?.map((items) => {
        return {
          id: items?.modality,
          revenue: items?.revenue,
          value: items?.revenue,
          label: items?.modality,
        };
      });
    setData(data);
  }

  return (
    <>
      {dataSerries && dataSerries?.length ? (
        <div className="pie-graph-container">
          <h1 className="graph-heading-container">bifurcation data type</h1>
          {bifurcationDataLoading ? (
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 70,
              }}
            ></Spin>
          ) : (
          <>
          <div className="radio-button-gender">
            <Radio.Group
              defaultValue={radioColumns && radioColumns[0]}
              buttonStyle="solid"
              onChange={handleChange}
              className="gender-selector"
            >
              {radioColumns &&
                radioColumns?.map((items) => {
                  return (
                    <Radio.Button value={items} key={items}>
                      {items}
                    </Radio.Button>
                  );
                })}
            </Radio.Group>
          </div>

          <ResponsivePie
            data={dataSerries}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "ruby",
                },
                id: "dots",
              },
              {
                match: {
                  id: "c",
                },
                id: "dots",
              },
              {
                match: {
                  id: "go",
                },
                id: "dots",
              },
              {
                match: {
                  id: "python",
                },
                id: "dots",
              },
              {
                match: {
                  id: "scala",
                },
                id: "lines",
              },
              {
                match: {
                  id: "lisp",
                },
                id: "lines",
              },
              {
                match: {
                  id: "elixir",
                },
                id: "lines",
              },
              {
                match: {
                  id: "javascript",
                },
                id: "lines",
              },
            ]}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
          </>)}
        </div>
      ) : (
        <div className="pie-graph-container">
          <h1 className="graph-heading-container">bifurcation data type</h1>
          <Empty />
        </div>
      )}
    </>
  );
};

export default PieChart;
