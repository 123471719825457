import { Card, Spin } from "antd";
import { FaRupeeSign } from "react-icons/fa";

const DataPatientCard = ({ totalRevenue, totalRevenueLoading }) => {
  return (
    <div className="col-container">
      <Card bordered={false}>
        <div className="content">
          <div className="icon rupee">
            <FaRupeeSign />
          </div>
          <div className="content-values">
            <h2 className="card-title">Total Revenue</h2>
            {totalRevenueLoading ? (
              <Spin />
            ) : (
              <span className="card-value">
                {totalRevenue
                  ? new Intl.NumberFormat("en-IN", {}).format(
                      parseInt(totalRevenue)?.toFixed(0)
                    )
                  : 0}
                <span className="decimal-value">
                  {Math.round((totalRevenue - parseInt(totalRevenue)) * 10)
                    ? `.${Math.abs(Math.round(
                        (totalRevenue - parseInt(totalRevenue)) * 10
                      ))}`
                    : ""}
                </span>
              </span>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DataPatientCard;
