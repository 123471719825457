import React, { useState, useEffect } from "react";
import { Empty, Spin } from "antd";
import moment from "moment";

import ReactApexCharts from "react-apexcharts";

const BarGraph = ({
  number_of_tests_appointment_per_day,
  appointmentDetailsLoading,
}) => {
  const [data, setData] = useState([]);
  const [series, setSeries] = useState([]);

  const [data2, setData2] = useState([]);

  let options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "pan",
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 1],
      curve: "straight",
    },
    colors: ["#2E93fA", "rgb(244, 117, 96)", "#546E7A", "#E91E63", "#FF9800"],

    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - " +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          ""
        );
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: data2,
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  useEffect(() => {
    if (
      number_of_tests_appointment_per_day &&
      number_of_tests_appointment_per_day.length
    ) {
      let modifiedData = number_of_tests_appointment_per_day?.map((items) => {
        return {
          date: moment(items?.date).format("DD/MM/YYYY"),
          number_of_tests: items?.number_of_tests,
          number_of_appointments: items?.number_of_appointments,
        };
      });
      setData(modifiedData);

      let dateValue = number_of_tests_appointment_per_day?.map((items) => {
        return moment(items?.date).format("DD/MM/YYYY");
      });

      let numberOfAppointments = number_of_tests_appointment_per_day?.map(
        (items) => {
          return items.number_of_appointments;
        }
      );

      let numberOfTest = number_of_tests_appointment_per_day?.map((items) => {
        return items.number_of_tests;
      });

      let series = [
        {
          name: "Number of appointments",
          data: numberOfAppointments,
        },
        {
          name: "Number of test",
          data: numberOfTest,
        },
      ];

      setSeries(series);

      setData2(dateValue);
    } else {
      setSeries([]);
      setData2([]);
    }
  }, [number_of_tests_appointment_per_day]);

  return (
    <div className="bar-graph-container no-padding">
      {data && data?.length ? (
        <>
          <h1 className="graph-heading-container left">Appointment matrix</h1>

          {appointmentDetailsLoading ? (
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 70,
              }}
            />
          ) : (
            <ReactApexCharts
              options={options}
              series={series}
              type="line"
              height={350}
            />
          )}
        </>
      ) : (
        <>
          <h1 className="graph-heading-container">
            bed occupancy (number of beds per day)
          </h1>
          <Empty />
        </>
      )}
    </div>
  );
};

export default BarGraph;
