import React, { useState } from "react";

import { RiLogoutBoxRLine } from "react-icons/ri";
import { BsSun } from "react-icons/bs";
import { FaHandsHelping } from "react-icons/fa";
import { BellOutlined } from "@ant-design/icons";
import { Layout, Menu, Switch } from "antd";

import { RightSideBarWrapper } from "./styles";
import SupportModal from "./Components/SupportModal";

const RightSideBar = ({ toggleDarkMode, darkMode, onLogOut }) => {
  const { Sider } = Layout;

  const [collapsed, setCollapsed] = useState(true);
  const [supportModal, setSupportModal] = useState(false);

  const openSupportModal = () => {
    setSupportModal(!supportModal);
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <RightSideBarWrapper>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        theme={darkMode ? "dark" : "light"}
        className={darkMode ? "dark-mode" : "normal"}
        width="120"
        reverseArrow="false"
        defaultcollapsed="true"
      >
        <Menu
          theme={darkMode ? "dark" : "light"}
          mode="inline"
          className={darkMode ? "dark-mode" : ""}
          defaultcollapsed="true"
        >
          <Menu.Item key="1" icon={<BellOutlined />}>
            Notifications
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={<FaHandsHelping />}
            // onClick={() => openSupportModal()}
          >
            Support
          </Menu.Item>
        </Menu>
        <Menu className={darkMode ? "bottom-btn dark-mode" : "bottom-btn"}>
          <Menu.Item key="4" icon={<BsSun />}>
            <Switch onChange={toggleDarkMode} />
          </Menu.Item>
          <Menu.Item key="3" icon={<RiLogoutBoxRLine />} onClick={onLogOut}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <SupportModal
        supportModal={supportModal}
        openSupportModal={openSupportModal}
      />
    </RightSideBarWrapper>
  );
};

export default RightSideBar;
