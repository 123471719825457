/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//importing common slices for store
import {
  loginUser,
  userSelector,
  clearState,
  fetchOtp,
  signWithOtp,
  signWithOtpByToken,
  fetchOtpByToken,
} from "../../../User/UserSlice";

//importing antd design component
import { Form, Select } from "antd";

//importing common components
import { errorNotification } from "../../../../components/common/Notification";
import { numberPrefix } from "../../../../const/SelectorPrefix";

//importing forms
import { CredentialsLoginForm, OtpLoginForm } from "./Components";

const FromContainer = ({ claimId, patientId, token }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Option } = Select;

  const [slideUp, setSlideUP] = useState(true);
  const [slideDown, setSlideDown] = useState(false);

  const { isSuccess, isError, isOtpSuccess, isOtpError, isFetching } =
    useSelector(userSelector);

  //useEffect to clear state onload
  useEffect(() => {
    // const value = queryString.parse(props.location.search);
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (claimId) {
      setSlideUP(false);
      setSlideDown(true);
    }
  }, [claimId]);

  //useEffect to trigger on error on success
  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      errorNotification();
    }

    if (isSuccess) {
      dispatch(clearState());

      history.push("/");
    }
  }, [isError, isSuccess]);

  //on credential form submit
  const onFinish = (values) => {
    dispatch(loginUser(values));
  };

  //on otp form submit
  const onFinishOtp = (values) => {
    if (!values.otpNumber && claimId) {
      dispatch(
        fetchOtpByToken({
          phone: values.phone,
          patient: patientId,
          token: token,
        })
      );
    } else if (!values.otpNumber) {
      dispatch(fetchOtp(values));
    } else if (claimId) {
      dispatch(
        signWithOtpByToken({
          otpNumber: values.otpNumber,
          patientId: patientId,
          token: token,
        })
      );
    } else {
      dispatch(signWithOtp(values));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        {numberPrefix.map((item) => {
          return (
            <Option value="91" key={item}>
              {item}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <>
      <div className="content-container">
        <div className="form-structure">
          <CredentialsLoginForm
            slideUp={slideUp}
            slideDown={slideDown}
            setSlideUP={setSlideUP}
            setSlideDown={setSlideDown}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            isFetching={isFetching}
          />
          <OtpLoginForm
            slideDown={slideDown}
            setSlideUP={setSlideUP}
            setSlideDown={setSlideDown}
            onFinish={onFinishOtp}
            onFinishFailed={onFinishFailed}
            prefixSelector={prefixSelector}
            isError={isOtpError}
            isSuccess={isOtpSuccess}
            isFetching={isFetching}
          />
        </div>
      </div>
    </>
  );
};

export default FromContainer;
