import React, { useState } from "react";

import { List, Avatar } from "antd";
import { Radio, Spin } from "antd";

const DoctorAdmission = ({ admissions, referralDataLoading }) => {
  const [selectedDataFlow, setSelectedDataFlow] = useState("dr");

  const handleChange = (value) => {
    setSelectedDataFlow(value.target.value);
  };

  return (
    <>
      {admissions && admissions?.dr ? (
        <List
          itemLayout="horizontal"
          dataSource={admissions[selectedDataFlow]}
          header={
            <div>
              <h1 className="graph-heading-container">
                Referrals Data for individual's admissions
              </h1>
              <div className="radio-button-gender">
                <Radio.Group
                  defaultValue="dr"
                  buttonStyle="solid"
                  onChange={handleChange}
                  className="gender-selector"
                >
                  <Radio.Button value="dr">Doctor</Radio.Button>
                  <Radio.Button value="pt">Patient</Radio.Button>
                  <Radio.Button value="on">Online</Radio.Button>
                  <Radio.Button value="ot">Other</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          }
          renderItem={(item) =>
            referralDataLoading ? (
              <Spin
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 70,
                }}
              />
            ) : (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar src="https://www.medstar911.org/wp-content/uploads/2019/11/Doctor-placeholder-image.jpg" />
                  }
                  title={
                    item?.doctor_profile?.full_name
                      ? item?.doctor_profile?.full_name
                      : item.referred_by
                  }
                  description={new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                  }).format(item?.revenue.toFixed(2))}
                  className="list-data"
                />
              </List.Item>
            )
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DoctorAdmission;
