import React, { useState, useEffect } from "react";
import { Empty, Spin } from "antd";
import { ResponsivePie } from "@nivo/pie";

const PieChart = ({ genderDetails, referralDataLoading }) => {
  const [patientData, setPatientData] = useState([]);

  useEffect(() => {
    let data1 = genderDetails?.map((items) => {
      return {
        referral_type:
          items.referral_type === "ot"
            ? "Others"
            : items.referral_type === "dr"
            ? "Doctor"
            : items.referral_type === "on"
            ? "Online"
            : items.referral_type === "self"
            ? "Self"
            : items.referral_type === "pt"
            ? "Patient"
            : items.referral_type === "pt",
        value: items.value,
      };
    });

    let data = data1?.map((items) => {
      return {
        id: `${items?.referral_type}`,
        referedBy: items?.referral_type,
        value: items?.value,
        label: `${items?.referral_type}`,
      };
    });

    setPatientData(data);
  }, [genderDetails]);

  return (
    <>
      {patientData && patientData?.length ? (
        <div className="pie-graph-container">
          <h1 className="graph-heading-container">age gender patient data</h1>
          {referralDataLoading ? (
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 70,
              }}
            />
          ) : (
            <ResponsivePie
              data={patientData}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              enableArcLabels={false}
              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: "ruby",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "c",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "go",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "python",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "scala",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "lisp",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "elixir",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "javascript",
                  },
                  id: "lines",
                },
              ]}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: 56,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          )}
        </div>
      ) : (
        <div className="pie-graph-container">
          <h1 className="graph-heading-container">age gender patient data</h1>
          <Empty />
        </div>
      )}
    </>
  );
};

export default PieChart;
