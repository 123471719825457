/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { DatePicker, Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";

import {
  getBifurcationData,
  getRevenuePerTest,
  diagnosticInformationSelector,
  getTotalRevenue,
} from "../../Slices/DiagnosticSlices";

import FilterButton from "./Components/FilterButtons";
import DataPatientCard from "./Components/DataCards";
import DoctorAdmission from "./Components/DoctorAdmission";
import Scrollbars from "react-custom-scrollbars";
import PieChart from "./Components/PieChar";
import moment from "moment";

const Revenue = () => {
  const { RangePicker } = DatePicker;
  const doctorProfile = JSON.parse(localStorage.getItem("doctorProfile"));

  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState([]);

  const [dateSelected, setDateSelected] = useState(7);
  const [activeButton, setActiveButton] = useState([]);

  function disableDateRanges(range = { startDate: false, endDate: false }) {
    const { startDate, endDate } = range;
    return function disabledDate(current) {
      let startCheck = true;
      let endCheck = true;
      if (startDate) {
        startCheck = current && current < moment(startDate, "YYYY-MM-DD");
      }
      if (endDate) {
        endCheck = current && current > moment(endDate, "YYYY-MM-DD");
      }
      return (startDate && startCheck) || (endDate && endCheck);
    };
  }

  const {
    bifurcationData,
    revenue,
    totalRevenue,
    totalRevenueLoading,
    revenueLoading,
    bifurcationDataLoading,
  } = useSelector(diagnosticInformationSelector);

  const dateFormat = "DD/MM/YYYY";

  const getDate = async (from_date, to_date, days) => {
    setDateSelected(days);
    if (days === 0) {
      dispatch(
        getBifurcationData({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );

      dispatch(
        getRevenuePerTest({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );

      dispatch(
        getTotalRevenue({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );
    } else {
      dispatch(
        getBifurcationData({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );

      dispatch(
        getRevenuePerTest({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );

      dispatch(
        getTotalRevenue({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(from_date).format("YYYY-MM-DD"),
          to_date: moment(to_date).format("YYYY-MM-DD"),
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getBifurcationData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      })
    );

    dispatch(
      getRevenuePerTest({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      })
    );

    dispatch(
      getTotalRevenue({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      })
    );
  }, []);

  const filterResult = () => {
    setActiveButton(null);
    if (selectedDate.length) {
      dispatch(
        getBifurcationData({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );

      dispatch(
        getRevenuePerTest({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );

      dispatch(
        getTotalRevenue({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );
    } else if (dateSelected === 0) {
      dispatch(
        getBifurcationData({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );

      dispatch(
        getRevenuePerTest({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );

      dispatch(
        getTotalRevenue({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );
    } else {
      dispatch(
        getBifurcationData({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );

      dispatch(
        getRevenuePerTest({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );

      dispatch(
        getTotalRevenue({
          token: localStorage.getItem("token"),
          locationId: doctorProfile?.selected_location,
          from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        })
      );
    }
  };

  function onChange(dateString) {
    setSelectedDate(dateString);
  }

  return (
    <>
      <Scrollbars style={{ height: "95vh" }}>
        <div className="header-container">
          <div className="range-selector">
            <RangePicker
              defaultValue={[moment(new Date()), moment(new Date())]}
              format={dateFormat}
              onChange={onChange}
              value={
                selectedDate?.length
                  ? [selectedDate[0], selectedDate[1]]
                  : dateSelected === 0
                  ? [moment(new Date()), moment(new Date())]
                  : [moment(new Date()), moment(new Date())]
              }
              className="custom-range-picker"
              disabledDate={disableDateRanges({
                startDate: new Date("2022-01-23"),
              })}
            />
            <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => filterResult()}
              className="filter-date-button"
            />
            <span className="warning-txt">
              *Please Select a range of date's to show the data insights
            </span>
          </div>
        </div>
        <FilterButton setFilterDate={getDate} setSelectedDate={setSelectedDate} activeButton={activeButton} setActiveButton={setActiveButton} />

        <div className="top-header-filter-revenue">
          <DataPatientCard
            totalRevenue={totalRevenue?.total_revenue}
            totalRevenueLoading={totalRevenueLoading}
          />
        </div>

        <div className="top-header-filter">
          <DoctorAdmission
            revenue_per_test_list={revenue?.revenue_per_test_list}
            revenueLoading={revenueLoading}
          />
          <PieChart
            test_data_type_bifurcation_data={
              bifurcationData?.test_data_type_bifurcation_data
            }
            bifurcationDataLoading={bifurcationDataLoading}
          />
        </div>
      </Scrollbars>
    </>
  );
};

export default Revenue;
