/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { TabWrapper } from "./tabStyle";
import Admission from "../Cards/Admission";
import Revenue from "../Cards/Revenue";
import Referals from "../Cards/Referal";

const MainContent = ({ activeTabKey }) => {
  const project = () => {
    switch (activeTabKey) {
      case 1:
        return <Admission />;
      case 2:
        return <Revenue />;

      case 4:
        return <Referals />;
      default:
        return <h1>No project match</h1>;
    }
  };
  return <TabWrapper>{project()}</TabWrapper>;
};

export default MainContent;
