import Button from "@mui/material/Button";
import moment from "moment";

const FilterButton = ({ setFilterDate, setSelectedDate, activeButton, setActiveButton }) => {


  const dateChange = (currentDate, days) => {
    let calc_date = new Date();
    if(days===7){
      calc_date.setDate(calc_date.getDate()-7)
    }
    if(days===30){
      calc_date.setMonth(calc_date.getMonth()-1)
    }
    if(days===365){
      calc_date.setFullYear(calc_date.getFullYear()-1)
    }
    if(calc_date < new Date("2022-01-23")){
      calc_date = new Date("2022-01-23")
    }
    setSelectedDate([moment(calc_date), moment(new Date())])
    setActiveButton(days);
    setFilterDate(calc_date, new Date(), days);
  };

  return (
    <div className="button-container">
      <Button
        variant="contained"
        className={
          activeButton === 0 ? "filter-button active" : "filter-button"
        }
        onClick={() => {
          dateChange(moment().format("YYYY-MM-DD"), 0);
        }}
      >
        Today
      </Button>
      <Button
        variant="contained"
        className={
          activeButton === 7 ? "filter-button active" : "filter-button"
        }
        onClick={() => {
          dateChange(moment().subtract(7, "d").format("YYYY-MM-DD"), 7);
        }}
      >
        Last Week
      </Button>
      <Button
        variant="contained"
        className={
          activeButton === 30 ? "filter-button active" : "filter-button"
        }
        onClick={() => {
          dateChange(moment().subtract(1, "months").format("YYYY-MM-DD"), 30);
        }}
      >
        Last Month
      </Button>
      <Button
        variant="contained"
        className={
          activeButton === 365 ? "filter-button active" : "filter-button"
        }
        onClick={() => {
          dateChange(moment().subtract(1, "year").format("YYYY-MM-DD"), 365);
        }}
      >
        Last Year
      </Button>
    </div>
  );
};

export default FilterButton;
